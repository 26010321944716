import { GruposModel } from "@/models/GruposModel";
import ApiService from "./ApiService";



 export const grupolist = async ( postData: any): Promise<Array<GruposModel>> => {
    const { data } = await ApiService.post(`/grupolist`, postData);
    return data;
 };

 export const grupodelete = async (codConcessionaria: number) => {
   const { data } = await ApiService.delete(`/grupodelete/${codConcessionaria}`);
   return data;
};

export const grupocreate = async (postData) => {
   const { data } = await ApiService.post(`/grupoadd`, postData);
   return data;
};

export const grupoView = async (codGrupo: number) => {
   const {data} = await ApiService.get(`/grupoview/${codGrupo}`);
   return data;
};


export default { grupolist, grupodelete, grupocreate, grupoView };